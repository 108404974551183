"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const VisibilitySensor = require("react-visibility-sensor");
const react_redux_1 = require("react-redux");
const react_router_1 = require("react-router");
const ivms = require("../ivms/ivms");
const lodash_1 = require("lodash");
const SectionTitle_1 = require("../components/SectionTitle");
const isNode = require("detect-node");
const helper_functions_1 = require("../utils/helper-functions");
const ThemaKomplex_1 = require("./ThemaKomplex");
const isPrerendered = isNode ? null : window.__ivms_ssr__;
;
;
;
class ThemenKomplex extends React.Component {
    constructor() {
        super(...arguments);
        this.state = {
            rendered_playlistset_items: [],
            inview_playlistset_ids: [],
            page_number: 0,
            isLoading: false,
            hasMore: true,
        };
    }
    shouldComponentUpdate(nextProps, nextState) {
        if (isNode && this.props.match.params.id !== 'undefined'
            && nextProps.match.params.id === 'undefined')
            return false;
        return true;
    }
    maybeAppendPlaylistSetItems() {
        const { rendered_playlistset_items, inview_playlistset_ids, page_number } = this.state;
        const { playlistset_items } = this.props;
        if (rendered_playlistset_items.length != inview_playlistset_ids.length) {
            let missingIds = lodash_1.dropWhile(inview_playlistset_ids, (id => {
                return !!lodash_1.find(playlistset_items, p => p.id === id);
            }));
            if (lodash_1.isEmpty(missingIds)) {
                var new_playlistset_items = [];
                inview_playlistset_ids.map((id, index) => {
                    if (index > rendered_playlistset_items.length) {
                        var item = lodash_1.find(playlistset_items, (p) => p.id === id);
                        new_playlistset_items.push(React.createElement("div", { key: index, className: "themen-item" },
                            React.createElement("a", { href: '/themen/' + item.slug, key: index + '-inner', className: "themen-item-inner" },
                                React.createElement("div", { className: "themen-item-image", style: { backgroundImage: `url(${helper_functions_1.getImageUrlBySlug(item, "2018-standard")})` }, key: index + '-image' }),
                                React.createElement("h3", { className: 'themen-item-title', key: index + '-link' }, item.title),
                                React.createElement("p", { key: index + '-desc', className: "themen-item-teaser" }, item.teaser))));
                    }
                    ;
                });
                this.setState(lodash_1.merge(this.state, {
                    rendered_playlistset_items: lodash_1.concat(this.state.rendered_playlistset_items, new_playlistset_items),
                    isLoading: false,
                }));
            }
            ;
        }
        ;
    }
    ;
    getNextPlaylistSetItems(isVisible) {
        if (isVisible && !lodash_1.isEmpty(this.props.all_playlistset_ids) && this.state.isLoading == false) {
            let newPageNumber = isNode ? 0 : this.state.page_number + 1;
            const { playlistset_items, restapi_url, all_playlistset_ids, getPlaylistSetFromId } = this.props;
            let slicedPlaylistSetIds = lodash_1.slice(all_playlistset_ids, newPageNumber * 24, (newPageNumber * 24) + 24);
            if (slicedPlaylistSetIds.length < 24) {
                setTimeout(() => this.setState(lodash_1.merge(this.state, { hasMore: false })), 1000);
            }
            var shouldLoad = false;
            slicedPlaylistSetIds.forEach(id => {
                let item = lodash_1.find(playlistset_items, p => p.id === id);
                if (lodash_1.isEmpty(item)) {
                    getPlaylistSetFromId(id, restapi_url);
                    if (!shouldLoad) {
                        shouldLoad = true;
                    }
                    ;
                }
                ;
            });
            this.setState(lodash_1.merge(this.state, {
                inview_playlistset_ids: lodash_1.concat(this.state.inview_playlistset_ids, slicedPlaylistSetIds),
                page_number: newPageNumber,
                isLoading: shouldLoad,
            }));
            if (!shouldLoad) {
                this.maybeAppendPlaylistSetItems();
            }
        }
        ;
    }
    ;
    UNSAFE_componentWillReceiveProps({ playlistset_items, all_playlistset_ids, match }) {
        let overviewView = typeof match.params.id === 'undefined' ? true : false;
        if (overviewView &&
            (all_playlistset_ids.length != this.props.all_playlistset_ids.length) &&
            (this.state.rendered_playlistset_items.length === 0)) {
            if (isPrerendered) {
                this.setState(lodash_1.merge(this.state, {
                    inview_playlistset_ids: lodash_1.slice(all_playlistset_ids, 0, 24),
                }));
            }
            else {
                setTimeout(() => this.getNextPlaylistSetItems(true), 0);
            }
        }
        setTimeout(() => this.maybeAppendPlaylistSetItems(), 0);
    }
    ;
    componentWillUpdate({ restapi_url, all_playlistset_ids, match }) {
        let overviewView = typeof match.params.id === 'undefined' ? true : false;
        if (overviewView && restapi_url && lodash_1.isEmpty(all_playlistset_ids)) {
            this.props.getAllPlaylistSetIds(restapi_url);
        }
        if (overviewView &&
            (all_playlistset_ids.length != this.props.all_playlistset_ids.length) &&
            (this.state.rendered_playlistset_items.length === 0)) {
            this.getNextPlaylistSetItems(true);
        }
        if (overviewView && (this.state.rendered_playlistset_items.length === 0)) {
            this.maybeAppendPlaylistSetItems();
        }
    }
    componentDidMount() {
        const { restapi_url, all_playlistset_ids, match } = this.props;
        let overviewView = typeof match.params.id === 'undefined' ? true : false;
        if (overviewView && restapi_url && lodash_1.isEmpty(all_playlistset_ids)) {
            this.props.getAllPlaylistSetIds(restapi_url);
        }
        if (overviewView && !isPrerendered && !lodash_1.isEmpty(all_playlistset_ids)) {
            this.getNextPlaylistSetItems(true);
        }
        if (!overviewView && !isNode) {
            scrollTo(0, 0);
        }
    }
    render() {
        if (isNode)
            this.componentDidMount();
        const { all_playlistset_ids, match } = this.props;
        const { rendered_playlistset_items, inview_playlistset_ids, page_number } = this.state;
        let overviewView = typeof match.params.id === 'undefined' ? true : false;
        if (overviewView) {
            return (React.createElement("div", { className: "themen" },
                React.createElement(SectionTitle_1.default, { title: "Themen" }),
                React.createElement("div", { className: "themen-parent" },
                    React.createElement("div", { className: "themen-container" }, this.state.rendered_playlistset_items),
                    this.state.hasMore &&
                        React.createElement(VisibilitySensor, { className: "themen-loader-container", onChange: this.getNextPlaylistSetItems.bind(this) },
                            React.createElement("div", { className: "spinner" },
                                React.createElement("div", { className: "rect1" }),
                                React.createElement("div", { className: "rect2" }),
                                React.createElement("div", { className: "rect3" }),
                                React.createElement("div", { className: "rect4" }),
                                React.createElement("div", { className: "rect5" }))))));
        }
        else {
            return (React.createElement(ThemaKomplex_1.default, { slug: match.params.id }));
        }
    }
}
const mapStateToProps = (state, ownProp) => {
    return {
        restapi_url: state.ivms.restapi_url,
        playlistset_items: state.ivms.playlistset_items,
        all_playlistset_ids: state.ivms.all_playlistset_ids,
    };
};
const mapDispatchToProps = (dispatch) => ({
    getAllPlaylistSetIds: (restapi_url) => dispatch(ivms.getAllPlaylistSetIds(restapi_url)),
    getPlaylistSetFromId: (id, restapi_url) => dispatch(ivms.getPlaylistSetFromId(id, restapi_url)),
});
exports.default = react_router_1.withRouter(react_redux_1.connect(mapStateToProps, mapDispatchToProps)(ThemenKomplex));
