"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const react_redux_1 = require("react-redux");
const Coverflow_1 = require("../components/Coverflow");
const Collections_1 = require("../components/Collections");
const MediaSwiper_1 = require("../components/MediaSwiper");
const SectionTitle_1 = require("../components/SectionTitle");
const ivms = require("../ivms/ivms");
const lodash_1 = require("lodash");
const memoizeOne = require("memoize-one/dist/memoize-one.cjs");
const isNode = require("detect-node");
const PlaylistSet_1 = require("../components/PlaylistSet");
const dctp_logo = require("../../images/starfield.gif");
;
;
;
const reduceSlug = memoizeOne((items) => {
    return lodash_1.reduce(items, (acc, item) => {
        if (item.module === 'container') {
            switch (item.unicode) {
                case "Coverflow":
                    acc.push(React.createElement(Coverflow_1.default, { key: item.id }));
                    break;
                case "Collections":
                    acc.push(React.createElement(Collections_1.default, { key: item.id }));
                    break;
            }
        }
        else if (item.module === 'playlist') {
            acc.push([
                React.createElement(SectionTitle_1.default, { title: item.unicode, key: item.id + 'title' }),
                React.createElement(MediaSwiper_1.default, { playlistID: item.id, key: item.id, bigItems: true })
            ]);
        }
        else if (item.module === 'playlistset') {
            acc.push(React.createElement(PlaylistSet_1.default, { key: item.id, playlistsetID: item.id }));
        }
        return acc;
    }, []);
});
class Home extends React.Component {
    constructor() {
        super(...arguments);
        this.state = {};
    }
    shouldComponentUpdate(nextProps, nextState) {
        if ((!this.props.items) ||
            (nextProps.items.length != this.props.items.length))
            return true;
        return false;
    }
    componentDidMount() {
        const { getContainerData, restapi_url, items } = this.props;
        if (restapi_url && lodash_1.isEmpty(items))
            getContainerData(1, restapi_url);
    }
    render() {
        const { restapi_url, items } = this.props;
        if (isNode)
            this.componentDidMount();
        return (React.createElement("div", { id: "app" }, items && reduceSlug(this.props.items)));
    }
}
exports.Home = Home;
const mapStateToProps = (state, ownProp) => {
    return lodash_1.merge({
        restapi_url: state.ivms.restapi_url,
        playlist_items: state.ivms.playlist_items,
    }, lodash_1.find(state.ivms.containers, (c) => { return c.slug === 'home'; }));
};
const mapDispatchToProps = (dispatch) => ({
    getContainerData: (containerId, restapi_url) => dispatch(ivms.getContainerData(containerId, restapi_url)),
});
exports.default = react_redux_1.connect(mapStateToProps, mapDispatchToProps)(Home);
