"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const redux_1 = require("redux");
const redux_thunk_1 = require("redux-thunk");
const ivms_redux_reducer_1 = require("./ivms/ivms-redux-reducer");
const isNode = require("detect-node");
exports.initialStoreState = {
    ivms: ivms_redux_reducer_1.initialIvmsState(),
};
const serverSideReducer = function (state, action) {
    if (action.type === "setIvmsState") {
        state.ivms = action.ivms;
        return state;
    }
    else {
        return state;
    }
};
const rootReducer = (state, action) => {
    let ivms_reduced_state = ivms_redux_reducer_1.IvmsReducer(state, action);
    return ivms_reduced_state;
};
exports.serverSideStore = redux_1.createStore(serverSideReducer, exports.initialStoreState);
const preloadedState = isNode ? null : window.__PRELOADED_STATE__;
exports.AppStore = redux_1.createStore(rootReducer, preloadedState || exports.initialStoreState, redux_1.compose(redux_1.applyMiddleware(redux_thunk_1.default), (!isNode && DEVELOPMENT && devToolsExtension) ? devToolsExtension() : f => f));
if (!isNode)
    delete window.__PRELOADED_STATE__;
