"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const react_redux_1 = require("react-redux");
const ivms = require("../ivms/ivms");
const SectionTitle_1 = require("./SectionTitle");
const lodash_1 = require("lodash");
const helper_functions_1 = require("../utils/helper-functions");
const isNode = require("detect-node");
const memoizeOne = require("memoize-one/dist/memoize-one.cjs");
;
;
class Collections extends React.Component {
    constructor() {
        super(...arguments);
        this.ensurePlaylistSetsFromSlug = memoizeOne((items) => {
            const { getPlaylistSetFromId, restapi_url, playlistset_items } = this.props;
            const slugPlaylistSetIds = helper_functions_1.getPlaylistSetIds(items);
            const allPlaylistSetIds = helper_functions_1.getPlaylistSetIds(playlistset_items);
            slugPlaylistSetIds.forEach(id => {
                if (!lodash_1.includes(allPlaylistSetIds, id)) {
                    getPlaylistSetFromId(id, restapi_url);
                }
            });
        });
    }
    componentDidMount() {
        const { getContainerData, restapi_url, items } = this.props;
        if (lodash_1.isEmpty(items))
            getContainerData(4, restapi_url);
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if ((typeof prevProps.items == 'undefined' || prevProps.items.length < 1) &&
            (typeof this.props.items != 'undefined' && this.props.items.length > 0))
            this.ensurePlaylistSetsFromSlug(this.props.items);
    }
    UNSAFE_componentWillMount() {
        if (isNode) {
            this.componentDidMount();
            this.ensurePlaylistSetsFromSlug(this.props.items);
        }
    }
    render() {
        const { playlistset_items, items } = this.props;
        const playlistIds = helper_functions_1.getPlaylistSetIds(this.props.items);
        const filtered_playlistset_items = helper_functions_1.filterPlaylistSetsByIds(playlistIds, playlistset_items);
        var settings = {
            arrows: false,
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1
        };
        return (React.createElement("div", { className: "collections-container" },
            React.createElement(SectionTitle_1.default, { title: "Overviews" }),
            React.createElement("div", { className: "collections-item-container" }, filtered_playlistset_items.map((item, index_inner) => {
                var className = (index_inner % 2) ? "collections-item-color right" : "collections-item-color left";
                return (React.createElement("div", { className: className, key: index_inner, style: { backgroundColor: `${item.tags[0].replace("color:", "#")}` } },
                    React.createElement("span", null, item.title),
                    React.createElement("a", { href: '/themenkomplex/' + item.slug })));
            }))));
    }
}
exports.Collections = Collections;
const mapStateToProps = (state, ownProp) => {
    return lodash_1.merge({
        restapi_url: state.ivms.restapi_url,
        playlistset_items: state.ivms.playlistset_items,
    }, lodash_1.find(state.ivms.containers, (c) => { return c.slug === 'collections'; }));
};
const mapDispatchToProps = (dispatch) => ({
    getPlaylistSetFromId: (id, restapi_url) => dispatch(ivms.getPlaylistSetFromId(id, restapi_url)),
    getContainerData: (containerId, restapi_url) => dispatch(ivms.getContainerData(containerId, restapi_url)),
});
exports.default = react_redux_1.connect(mapStateToProps, mapDispatchToProps)(Collections);
