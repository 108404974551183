"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function removeQueryParams(url) {
    return url.split('?')[0];
}
exports.removeQueryParams = removeQueryParams;
function trailingSlash(path) {
    path = path.trim();
    return path.charAt(path.length - 1) === '/' ? path : path + '/';
}
exports.trailingSlash = trailingSlash;
function cacheBuster() {
    return '?cache=' + Math.floor(Math.random() * 10000000000);
}
exports.cacheBuster = cacheBuster;
