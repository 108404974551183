"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const react_router_dom_1 = require("react-router-dom");
const react_redux_1 = require("react-redux");
const Home_1 = require("./pages/Home");
const Filme_1 = require("./pages/Filme");
const Themen_1 = require("./pages/Themen");
const Header_1 = require("./components/Header");
const NotFound_1 = require("./components/NotFound");
const ivmsConfig = require("./ivms/ivms-config");
const Footer_1 = require("./components/Footer");
const Mobile_1 = require("./components/Mobile");
const isNode = require("detect-node");
const ThemenKomplex_1 = require("./pages/ThemenKomplex");
class RootProvider extends React.Component {
    componentDidMount() {
        this.props.getIvmsConfig();
    }
    render() {
        if (isNode)
            this.props.getIvmsConfig();
        if (!this.props.restapi_url && !isNode) {
            return (React.createElement("div", null));
        }
        else {
            return (React.createElement("div", null,
                React.createElement(Mobile_1.default, null),
                React.createElement(Header_1.default, null),
                React.createElement(react_router_dom_1.Switch, null,
                    React.createElement(react_router_dom_1.Route, { exact: true, path: "/", component: Home_1.default }),
                    React.createElement(react_router_dom_1.Route, { path: "/filme/:id?", component: Filme_1.default }),
                    React.createElement(react_router_dom_1.Route, { path: "/themen/:id?", component: Themen_1.default }),
                    React.createElement(react_router_dom_1.Route, { path: "/themenkomplex/:id?", component: ThemenKomplex_1.default }),
                    React.createElement(react_router_dom_1.Route, { component: NotFound_1.NotFound })),
                React.createElement(Footer_1.Footer, null)));
        }
    }
}
const mapStateToProps = (state, ownProp) => {
    return { restapi_url: state.ivms.restapi_url };
};
const mapDispatchToProps = {
    getIvmsConfig: ivmsConfig.getConfig,
};
const Root = react_redux_1.connect(mapStateToProps, mapDispatchToProps)(RootProvider);
exports.default = Root;
