"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const object_assign = require("object-assign");
const url_utils_1 = require("../utils/url-utils");
const lodash_1 = require("lodash");
exports.initialIvmsState = () => ({
    config: {},
    restapi_url: "",
    media_items: [],
    playlist_items: [],
    playlistset_items: [],
    all_playlists_ids: [],
    all_playlistset_ids: [],
    all_media_ids: [],
    containers: [],
});
function mergeIvms(state, objectToMerge) {
    let prevIvms = typeof state.ivms == 'undefined' ? {} : state.ivms;
    let merged = object_assign({}, prevIvms || {}, objectToMerge);
    let new_state = { ivms: merged };
    state = new_state;
    return state;
}
function IvmsReducer(state, action) {
    if (typeof state == 'undefined') {
        let init_state = { ivms: exports.initialIvmsState() };
        return init_state;
    }
    switch (action.type) {
        case "IVMS_INITIALIZE_CONFIG":
            var restapiUrl = action.ivmsConfig.restapi_live;
            restapiUrl = url_utils_1.trailingSlash(restapiUrl);
            return mergeIvms(state, { config: action.ivmsConfig, restapi_url: restapiUrl });
            break;
        case "IVMS_MEDIA_FETCH_SPEC":
            return mergeIvms(state, { media_items: lodash_1.concat(state.ivms.media_items || [], [action.media_item]) });
            break;
        case "IVMS_PLAYLIST_FETCH_SPEC":
            return mergeIvms(state, { playlist_items: lodash_1.concat(state.ivms.playlist_items || [], [action.playlist_item]) });
            break;
        case "IVMS_PLAYLISTS_FETCH_ALL_IDS":
            return mergeIvms(state, {
                all_playlists_ids: lodash_1.concat(state.ivms.all_playlists_ids || [], action.all_playlists_ids),
            });
            break;
        case "IVMS_PLAYLISTSET_FETCH_SPEC":
            return mergeIvms(state, {
                playlistset_items: lodash_1.concat(state.ivms.playlistset_items || [], action.playlistset_item),
            });
            break;
        case "IVMS_PLAYLISTSETS_FETCH_ALL_IDS":
            return mergeIvms(state, {
                all_playlistset_ids: lodash_1.concat(state.ivms.all_playlistset_ids || [], action.all_playlistset_ids),
            });
            break;
        case "IVMS_MEDIA_FETCH_ALL_IDS":
            return mergeIvms(state, {
                all_media_ids: action.all_media_ids,
            });
            break;
        case "IVMS_CONTAINER_DATA_FETCH":
            return mergeIvms(state, {
                containers: lodash_1.concat(state.ivms.containers || [], [action.container]),
            });
            break;
        case "CLEAR_ALL":
            const restapi_url = state.ivms.restapi_url;
            state.ivms = exports.initialIvmsState();
            state.ivms.restapi_url = restapi_url;
            return state;
            break;
        case "GET_CONFIG_FROM_SERVER_CACHE":
            return mergeIvms(state, {
                config: Object.assign({}, action.ivmsConfig),
                restapi_url: action.restapi_url,
            });
            break;
    }
    return state;
}
exports.IvmsReducer = IvmsReducer;
