"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const react_redux_1 = require("react-redux");
require("videojs-contrib-hls");
const ivms = require("../ivms/ivms");
const lodash_1 = require("lodash");
const react_device_detect_1 = require("react-device-detect");
var videojsEs5 = require("video.js");
const videoJsOptions = {
    autoplay: true,
    controls: true,
    textTrackSettings: false,
    sources: [],
};
;
;
;
class VideoPlayer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            videojs_instantiated: false,
            media_not_found: false,
        };
        this.player = undefined;
        this.videoNode = undefined;
    }
    ;
    instantiateVideoJs(restapi_url) {
        const { getMediaFromSlug, media_items, slug } = this.props;
        if (restapi_url) {
            const item = lodash_1.find(media_items, m => m.slug === slug);
            if (lodash_1.isEmpty(item) || typeof item === 'undefined') {
                getMediaFromSlug(slug, restapi_url, () => this.setState(lodash_1.merge(this.state, { media_not_found: true })));
            }
            else {
                if (!this.videoNode)
                    return setTimeout(this.instantiateVideoJs.bind(this), 10);
                this.setState(lodash_1.merge(this.state, { videojs_instantiated: true }));
                let optionsWithStream = lodash_1.merge(videoJsOptions, { sources: ivms.getStreamUrl(item.uuid, "720p", item.is_wide),
                    poster: item.images[0].url, autoplay: false });
                this.player = videojsEs5(this.videoNode, optionsWithStream).ready(function () {
                    this.addClass('vjs-default-skin');
                    this.addClass('vjs-big-play-centered');
                    this.src(ivms.getStreamUrl(item.uuid, "720p", item.is_wide));
                    if (react_device_detect_1.isMobile) {
                    }
                });
            }
        }
    }
    ;
    componentWillReceiveProps({ restapi_url, media_items }) {
        if (!this.state.videojs_instantiated &&
            (restapi_url && this.props.media_items.length != media_items.length) ||
            (restapi_url != this.props.restapi_url)) {
            this.instantiateVideoJs(restapi_url);
        }
    }
    ;
    componentDidMount() {
        if (!this.state.videojs_instantiated) {
            this.instantiateVideoJs(this.props.restapi_url);
        }
    }
    ;
    componentWillUnmount() {
        if (this.player) {
            this.player.dispose();
        }
    }
    ;
    render() {
        const { media_items, slug } = this.props;
        const item = lodash_1.find(media_items, m => m.slug === slug);
        if (item) {
            return (React.createElement("div", { className: "video-js-container" },
                React.createElement(react_device_detect_1.MobileView, null,
                    React.createElement("style", null,
                        `.vjs-paused.vjs-has-started .vjs-big-play-button { display: block;}` +
                            react_device_detect_1.isAndroid ? '.vjs-control-bar {height: 6.0em!important}' +
                            '.vjs-remaining-time {padding-top: 14px!important;}' +
                            '.vjs-remaining-time-display {font-size: 20px}' +
                            '.vjs-volume-control,.vjs-mute-control {display: none!important;}' +
                            '.vjs-progress-holder {margin: 0!important; height: 8px!important;}' +
                            '.video-js .vjs-play-progress:before {font-size: 1.9em!important;}' +
                            '.video-js button {font-size: 20px!important}' :
                            `.vjs-control-bar { display: none!important; }`,
                        " ")),
                React.createElement("div", { className: "c-player__screen", "data-vjs-player": "true" },
                    React.createElement("video", { ref: (node) => node ? this.videoNode = node : null, className: "video-js" }))));
        }
        else {
            if (this.state.media_not_found) {
                return (React.createElement("div", null,
                    React.createElement("h1", null, "Error:"),
                    React.createElement("h2", null,
                        "Media ",
                        this.props.slug,
                        " wurde nicht gefunden")));
            }
            return (React.createElement("div", null));
        }
    }
    ;
}
;
const mapStateToProps = (state, ownProp) => {
    return {
        restapi_url: state.ivms.restapi_url,
        playlist_items: state.ivms.playlist_items,
        media_items: state.ivms.media_items,
        slug: ownProp.slug,
    };
};
const mapDispatchToProps = (dispatch) => ({
    getMediaFromId: (id, restapi_url) => dispatch(ivms.getMediaFromId(id, restapi_url)),
    getMediaFromSlug: (slugName, restapi_url, error_cb) => dispatch(ivms.getMediaFromSlug(slugName, restapi_url, error_cb)),
});
exports.default = react_redux_1.connect(mapStateToProps, mapDispatchToProps)(VideoPlayer);
