"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const react_device_detect_1 = require("react-device-detect");
const lodash_1 = require("lodash");
;
const mobile_style_common = `
body {  padding:0 0%!important; }
`;
const mobile_style_landscape = `
.bm-burger-button { display: none!important;}
.header-inline-items {display: block!important;}
 header {zoom: 100%;}
.themen .themen-parent .themen-container .themen-item {
  flex-basis: 250px;
}
`;
const mobile_style_portrait = `
.bm-burger-button { display: block!important;}
.header-inline-items {display: none!important;}

 header {zoom: 120%;}
 .menu li {
    font-size: 40px!important;
}
 .section-title h1 {
    margin-top: -5px;
    margin-left: 40px;
    font-size: 28px!important;
}

.section-title .section-title-bullet {
    top: 0px;
    width: 25px!important;
    height: 25px!important;
}

p,a {
    font-size: 20px!important;
}


.coverflow-text-container p {
  display: none;
}

.collections-image-container a {
  line-height: 10vw;
}

.collections-image-container .collections-image-container-outer {
  flex-basis: 100%;
}

.collections-image-container .collections-image-container-img {
  height: 40vw;
}

.media-swiper .media-swiper-big-item {
  
}

.themen-item {
  flex-basis: 100%!important;
}

.themen-item-inner {
  width: 100%!important;
}


.themen-item-teaser {
  font-size: 22px!important;
}

.themen .themen-parent .themen-container .themen-item-title + div {
  margin-bottom: 42px;
}

.themen .themen-parent .themen-container .time-duration {
  bottom: 36px;
}

.thema-container .thema-media-grid .thema-item {
  flex-basis: 100%;
}

.thema-container .thema-media-grid .thema-item-inner {
  width: 100%;
}

.thema-container .thema-media-grid .thema-item-image {
  height: 50vw;
}

.thema-subtitle {
  margin-top: 20px;
  font-size: 20px;
}

.thema-description {
  line-height: 40px;
}

.themen .themen-parent .themen-container .themen-item-image {
  height: 50vw;
}


.film-container .film-playlist-subtitle {
      margin: 40px 36px;
}

.film-container .film-title {
  font-size: 38px;
}

.film-container .film-subtitle {
    font-size: 28px;
    width: 100%;
}

`;
const orientationEventListener = function () {
    setTimeout(() => this.setState(lodash_1.merge(this.state, {
        view_is_landscape: matchMedia("(orientation: landscape)").matches,
    })), 100);
    setTimeout(() => this.setState(lodash_1.merge(this.state, {
        view_is_landscape: matchMedia("(orientation: landscape)").matches,
    })), 500);
    setTimeout(() => this.setState(lodash_1.merge(this.state, {
        view_is_landscape: matchMedia("(orientation: landscape)").matches,
    })), 1000);
};
class Mobile extends React.Component {
    constructor() {
        super(...arguments);
        this.state = {
            view_is_landscape: react_device_detect_1.isMobile ? matchMedia("(orientation: landscape)").matches : false
        };
    }
    componentDidMount() {
        if (react_device_detect_1.isMobile) {
            addEventListener('orientationchange', orientationEventListener.bind(this));
            orientationEventListener.bind(this)();
        }
    }
    ;
    componentWillUnmount() {
        if (react_device_detect_1.isMobile && react_device_detect_1.isBrowser) {
            removeEventListener('orientationchange', orientationEventListener.bind(this));
        }
    }
    ;
    render() {
        const landscape = this.state.view_is_landscape;
        if (landscape) {
            return (React.createElement(react_device_detect_1.MobileView, null,
                React.createElement("style", null, mobile_style_common + ' ' + mobile_style_landscape)));
        }
        else {
            return (React.createElement(react_device_detect_1.MobileView, null,
                React.createElement("style", null, mobile_style_common + ' ' + mobile_style_portrait)));
        }
    }
}
exports.default = Mobile;
