"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const react_slick_1 = require("react-slick");
const react_redux_1 = require("react-redux");
const KeyboardArrowLeft_1 = require("@material-ui/icons/KeyboardArrowLeft");
const KeyboardArrowRight_1 = require("@material-ui/icons/KeyboardArrowRight");
const ivms = require("../ivms/ivms");
const lodash_1 = require("lodash");
const helper_functions_1 = require("../utils/helper-functions");
const isNode = require("detect-node");
;
;
;
class MediaSwiper extends React.Component {
    constructor() {
        super(...arguments);
        this.state = {
            media_items_ensured: false,
        };
    }
    ensureMediaItems(playlist_item) {
        const { playlist_items, media_items, restapi_url, playlistID, getMediaFromId } = this.props;
        let containsMedia = playlist_item.contains_media;
        containsMedia.forEach(item => {
            let id = item.pk;
            let res = lodash_1.find(media_items, mi => {
                return mi.id === id;
            });
            if (!res) {
                getMediaFromId(id, restapi_url);
            }
        });
        this.setState(lodash_1.merge(this.state, { media_items_ensured: true }));
    }
    componentDidUpdate() {
        const { playlist_items, playlistID } = this.props;
        let playlist_item = lodash_1.find(playlist_items, pi => {
            return pi.id === playlistID;
        });
        if (playlist_item && !this.state.media_items_ensured) {
            this.ensureMediaItems(playlist_item);
        }
    }
    ;
    componentDidMount() {
        const { playlist_items, restapi_url, playlistID, getPlaylistFromId } = this.props;
        let playlist_item = lodash_1.find(playlist_items, pi => {
            return pi.id === playlistID;
        });
        if (!playlist_item) {
            getPlaylistFromId(playlistID, restapi_url);
        }
        else {
            this.ensureMediaItems(playlist_item);
        }
    }
    UNSAFE_componentWillMount() {
        const { playlist_items, restapi_url, playlistID, getPlaylistFromId } = this.props;
        if (isNode) {
            this.componentDidMount();
        }
    }
    render() {
        const { playlist_items, playlistID, media_items, bigItems, restapi_url } = this.props;
        var playlist_item = lodash_1.find(playlist_items, pi => pi.id === playlistID);
        var settings = {
            centerMode: true,
            slidesToShow: 3,
            arrows: false
        };
        if (playlist_item) {
            const containsMedia = playlist_item.contains_media;
            const playlistMedia = playlist_item.media;
            return (React.createElement("div", { className: "media-swiper" },
                bigItems && React.createElement("div", { className: "cover", style: { backgroundImage: `url(${helper_functions_1.getImageUrlBySlug(playlist_item, "2018-flat")})` } }),
                React.createElement("div", { className: "swiper-container" },
                    React.createElement(KeyboardArrowLeft_1.default, { style: { fill: "#ffffff" }, className: "arrow-left", onClick: () => this.refs.slider.slickPrev() }),
                    React.createElement(KeyboardArrowRight_1.default, { style: { fill: "#ffffff" }, className: "arrow-right", onClick: () => this.refs.slider.slickNext() }),
                    React.createElement(react_slick_1.default, Object.assign({ ref: "slider" }, settings), playlistMedia.map((id, index) => {
                        let media_item = lodash_1.find(media_items, mi => mi.id === id);
                        return (React.createElement("div", { key: index },
                            React.createElement("a", { href: '/filme/' + ((media_item && media_item.slug) || ''), key: 'link-' + index, className: 'swiper-item' },
                                React.createElement("div", { className: 'swiper-item-content' },
                                    media_item &&
                                        React.createElement("div", { className: 'swiper-item-image', style: { backgroundImage: `url(${helper_functions_1.getSmallestImage(media_item.images)})` }, key: index + '-image' }),
                                    media_item &&
                                        React.createElement("h3", { className: 'swiper-item-title', key: index + '-link' }, media_item.title),
                                    media_item &&
                                        React.createElement("p", { className: 'swiper-item-description', key: index + '-desc' }, media_item.teaser)))));
                    })))));
        }
        else {
            return (React.createElement("div", null));
        }
    }
}
exports.MediaSwiper = MediaSwiper;
const mapStateToProps = (state, ownProp) => {
    return {
        restapi_url: state.ivms.restapi_url,
        playlist_items: state.ivms.playlist_items,
        media_items: state.ivms.media_items,
        playlistID: ownProp.playlistID,
        bigItems: ownProp.bigItems,
    };
};
const mapDispatchToProps = (dispatch) => ({
    getPlaylistFromId: (id, restapi_url) => dispatch(ivms.getPlaylistFromId(id, restapi_url)),
    getMediaFromId: (id, restapi_url) => dispatch(ivms.getMediaFromId(id, restapi_url)),
});
exports.default = react_redux_1.connect(mapStateToProps, mapDispatchToProps)(MediaSwiper);
