"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const dctp_logo = require("../../images/dctp-tv.jpg");
const search_icon = require("../../images/icon-search.png");
class Header extends React.Component {
    constructor() {
        super(...arguments);
        this.state = {
            menuOpen: false
        };
    }
    handleStateChange(state) {
        this.setState({ menuOpen: state.isOpen });
    }
    closeMenu() {
        this.setState({ menuOpen: false });
    }
    toggleMenu() {
        this.setState({ menuOpen: !this.state.menuOpen });
    }
    render() {
        return (React.createElement("header", null,
            React.createElement("a", { href: "/", className: "logo_container", style: { backgroundImage: `url(${dctp_logo})` } }),
            React.createElement("ul", { className: "header-inline-items" },
                React.createElement("li", null,
                    React.createElement("a", { href: "http://magazin.dctp.tv", className: "nav-item" }, "Magazin")),
                React.createElement("li", null,
                    React.createElement("a", { href: "#", className: "nav-item" }, "Impressum"))),
            React.createElement("div", { className: "search" },
                React.createElement("input", { placeholder: "SUCHE" }),
                React.createElement("img", { src: "/images/icon-search.png" }))));
    }
}
exports.default = Header;
